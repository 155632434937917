<template>
	<z-path-base-card
		:image="path.image && path.image.path"
		:title="path.name"
		:description="path.description"
		:url="path._url || path.url"
		:prirucka="prirucka"
	>
		<template v-slot:footer-left>
			<div v-if="!isLoggedIn && path.slug === 'prirucka-prezitia-na-divokom-webe' && path.is_public == '1'" class="d-flex align-items-center w-color-dark-green">
				<svg-open-lock-icon class="open-lock-icon" />
				<span>Bez prihlásenia</span>
			</div>
		</template>
		<template v-slot:footer-right>
			<z-toggle-starred :model="path.slug !== 'prirucka-prezitia-na-divokom-webe' ? 'path' : 'course'" :id="path.id"
				:starred="path.is_starred_by_active_user"
				:starredText="path.slug !== 'prirucka-prezitia-na-divokom-webe' ? 'Séria bola pridaná medzi Moje kurzy!' : undefined"
				:unstarredText="path.slug !== 'prirucka-prezitia-na-divokom-webe' ? 'Séria bola odstránená z Mojich kurzov!' : undefined"
			/>
		</template>
	</z-path-base-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		'z-path-base-card':					() => import('./z-path-base-card.vue'),
		'z-toggle-starred': 		() => import('@/plugins/lib@_component/toggle-starred/z-toggle-starred.vue'),
		'svg-open-lock-icon': 	() => import('../../../appzmudri@component/course-card/assets/open-lock.svg?inline')
	},
	props: {
		path: { required: true, type: Object },
		prirucka: { required: false, type: Boolean, default: false }
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		]),
	}
}
</script>

<style lang="scss" scoped>
.z-card {
	width: initial !important;

	.footer {
		a {
			font-family: 'Boing', sans-serif;
		}
	}
}

.open-lock-icon {
	width: 1.5rem;
	margin-right: 0.5rem;
}
</style>